<template>
  <div>
    <div class="home section">
      <div class="container home-btns">
        <router-link :to="{ name: 'display_index' }" class="home-btns__item">
          <i class="fas fa-desktop"></i>
        </router-link>
        <router-link :to="{ name: 'list' }" class="home-btns__item">
          <i class="fas fa-list-ol"></i>
        </router-link>
        <router-link :to="{ name: 'generator' }" class="home-btns__item">
          <i class="fas fa-cog"></i>
        </router-link>
        <router-link :to="{ name: 'record_index' }" class="home-btns__item">
          <i class="fas fa-trophy"></i>
        </router-link>
      </div>
    </div>
    <div class="game-footer" style="margin-top: -50px">
      <div class="container game-footer__inner">
        <div class="game-footer__left">
          <div class="game-footer__points" style="font-weight: normal">
            Orange Academy
          </div>
        </div>

        <div class="game-footer__right" style="white-space: nowrap">
          <a
            href="https://telegram.me/oama_admin"
            style="margin-right: 5px; color: #333"
          >
            <i class="fa fa-user"></i>
          </a>
          <a href="https://isoftware.uz" target="_blank" style="color: #333">
            <i class="fa fa-tag"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>
